<template>
  <ag-charts-vue :options="options" style="height: 350px; width: 500px"></ag-charts-vue>
</template>

<script>
import { AgChartsVue } from 'ag-charts-vue';

export default {
  components: {
    'ag-charts-vue': AgChartsVue,
  },
  props: {
    data: { type: Object, required: true },
    text: { type: String, required: true },
    xKeyIdentifier: { type: String, required: true },
  },
  data: function () {
    return {
      options: null,
      allCount: 1,
    };
  },
  watch: {
    data(newData) {
      this.allCount = newData.allCount;
      const options = { ...this.options };
      options.data = newData.data;
      this.options = options;
    },
  },
  created() {
    this.allCount = this.data?.allCount;
    this.options = {
      autoSize: true,
      data: this.data.data,
      theme: {
        baseTheme: 'ag-pastel',
        overrides: {
          common: {
            background: {
              fill: '#f8f9fa',
            },
          },
          column: {
            series: {
              strokeWidth: 0,
              highlightStyle: {
                item: {
                  strokeWidth: 0.5,
                },
                series: {
                  strokeWidth: 1,
                  dimOpacity: 0.2,
                },
              },
            },
          },
        },
      },
      title: {
        text: this.text,
        fontSize: 14,
        spacing: 15,
      },
      series: [
        {
          type: 'column',
          xKey: this.xKeyIdentifier,
          yKey: 'count',
          label: {
            formatter: params => {
              let percentageOfAll = 0;
              if (parseInt(this.allCount) > 0) {
                percentageOfAll = Math.round((params.value / this.allCount) * 100);
              }
              return `${percentageOfAll}%`;
            },
            placement: 'outside',
          },
        },
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
        },
        {
          type: 'number',
          position: 'left',
        },
      ],
      overlays: {
        noData: {
          text: '',
        },
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>
